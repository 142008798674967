import { makeStyles } from "@material-ui/core";
const ComponentStyle = makeStyles((theme) => ({
  input: {
    // border: "2px solid #d8d8d8",
    width: "99%",
    // padding: ".5rem 0rem",
    margin: ".3rem 0rem",
    textAlign: "center",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4c4c4",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#25bcfb",
    },
  },

  cssLabel: {
    color: "#757575",
    "&.Mui-focused": {
      color: "#25bcfb",
    },
  },

  placeHolder: {},
  buttonStyle: {
    borderRadius: "2px",
    width: "100%",
    padding: ".8rem 0rem",
    borderRadius: "2px",
    margin: ".3rem 0rem",
    backgroundColor: "#25bcfb",
    textAlign: "center",
    color: "white",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#25bcfb!important",
      color: "white",
    },
  },
}));

export default ComponentStyle;
