import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { withRouter, Redirect } from "react-router-dom";
import Copyright from "../Copyright";
import loginBackground from "../../images/login_background.jpg";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    //for mobile
    "@media (min-width: 320px) and (max-width: 768px)": {
      height: "auto",
    },
  },
  image: {
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    //for mobile and tablets
    "@media (min-width: 320px) and (max-width: 1000px)": {
      display: "none",
    },
    "@media (max-width: 320px)": {
      display: "none",
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    //for mobile and tablets
    "@media (min-width: 320px) and (max-width: 1000px)": {
      minWidth: "200px",
      maxWidth: "300px",
    },
    "@media (max-width: 320px)": {
      minWidth: "200px",
      maxWidth: "280px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#25bcfb",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#25bcfb!important",
      color: "#ffffff",
    },
  },

  Myroot: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4c4c4",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#25bcfb",
    },
  },

  cssLabel: {
    color: "#757575",
    "&.Mui-focused": {
      color: "#25bcfb",
    },
  },
  learnMore: {
    marginTop: 10,
    backgroundColor: "#25bcfb",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#25bcfb!important",
      color: "#ffffff",
    },
  },
}));

function Login(props) {
  const [state, setState] = useState({
    csrf_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
    username: "",
    password: "",
    successMessage: null,
  });

  useEffect(() => {
    console.log("this.props.location.state", history);
    showAlertMessage();
    localStorage.setItem("step", 2);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const classes = useStyles();

  const handleSubmitClick = (e) => {
    e.preventDefault();
    const payload = {
      user: {
        username: state.username,
        password: state.password,
      },
    };
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    };
    axios
      .post("/users/sign_in.json", payload, { headers: headers })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            successMessage: "Login successful. Redirecting to home page..",
          }));
          redirectToHome(response.data);
          // props.showAlert({message: '', severity: "success"})
        } else if (response.code === 204) {
          props.showAlert({
            message: "Username and password do not match",
            severity: "error",
          });
        } else {
          props.showAlert({
            message: "Username does not exists",
            severity: "error",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        props.showAlert({
          message: "Username and password do not match",
          severity: "error",
        });
      });
  };

  const redirectToHome = ({ redirect }) => {
    props.history.push(redirect);
    window.location.reload(false);
  };
  const redirectToForgotPassword = () => {
    props.showAlert({ message: null, severity: "error" });
    props.history.push("/users/password/new");
  };
  const logo = document.querySelector("#logo")?.getAttribute("src");

  const showAlertMessage = () => {
    if (history.state?.state?.reset) {
      props.showAlert({
        message:
          "You have successfully changed your password. You can now login into your account below",
        severity: "success",
      });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <Grid
          style={{
            background: "#fff",
            maxWidth: "41%",
            borderRadius: 5,
            marginLeft: "3%",
            padding: "20px",
          }}
        >
          <Typography
            style={{ color: "#000", fontWeight: "bold", fontSize: 18 }}
          >
            Gather reviews. Gather feedback.
          </Typography>
          <Typography
            style={{ color: "#000", fontWeight: "bold", fontSize: 18 }}
          >
            Understand your customers.
          </Typography>
          <Typography
            style={{
              color: "#000",
              fontSize: 16,
              lineHeight: "normal",
              padding: "20px 0px",
            }}
          >
            Send review and feedback requests automatically while gathering key
            insights to understand your customers.
          </Typography>
          <ul style={{ fontSize: 14, color: "#000", listStyle: "inside" }}>
            <li>Centralize all your reviews in one place</li>
            <li>Send review requests</li>
            <li>Send feedback requests (NPS, CSAT, etc.)</li>
            <li>Display reviews on your website</li>
            <li>Share reviews and feedback to social media</li>
            <li>Understand text sentiment</li>
          </ul>
          <Button
            variant="contained"
            color="primary"
            className={classes.learnMore}
            onClick={() =>
              window.open("https://www.reviewmaiden.com", "_blank")
            }
          >
            Learn More
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        square
        style={{ boxShadow: "none" }}
      >
        <div className={classes.paper}>
          <Grid item sm={5}>
            <Link href="https://www.reviewmaiden.com" target="_blank">
              <img src={logo} className={classes.logo} />
            </Link>
          </Grid>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="User Name"
            name="username"
            autoComplete="username"
            autoFocus
            value={state.username}
            onChange={handleChange}
            className={classes.Myroot}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={state.password}
            onChange={handleChange}
            className={classes.Myroot}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember_me"
                style={{
                  color: "#26bcfb",
                }}
              />
            }
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmitClick}
          >
            Log in
          </Button>
          <form action="/auth/square" method="post" style={{ width: "100%"}}>
            <input name="authenticity_token" value={state.csrf_token} hidden="hidden"/>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{margin: "0px 0px 10px 0px"}}
            >
              Login using Square
            </Button>
          </form>
          <Grid container>
            <Grid item xs>
              <Link
                component="button"
                variant="body2"
                style={{ color: "#25bcfb" }}
                onClick={() => {
                  redirectToForgotPassword();
                }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                onClick={() => {
                  window.location.href = "/landing";
                }}
                variant="body2"
                target="_blank"
                style={{ color: "#25bcfb" }}
              >
                {"Don't have an account?"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              Review Maiden is a business reputation management tool.
            </Typography>
          </Box>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
export default withRouter(Login);
