import * as React from 'react';
import { useTheme, styled } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// const FormControlStyling = styled(Page)(({ theme }) => ({
//   "& .MuiFormControl-root" : {
//     margin: theme.spacing(1),
//   },
//   "& label" : {
//     background: 'white'
//   }
// }));


{/*const Item = () => <MenuItem value='active'>{ industry }</MenuItem>*/}

const MenuItems = (props) => props.industries.map(industry => <MenuItem value='active'>{ industry }</MenuItem>)



export default function MultipleSelect() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("")
 
  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
      <FormControl style={{ width: '100%', border: "1px solid #c4c4c4", borderRadius: "5px", height: "56px"}}>
        <InputLabel className="industry-label">Industry *</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={status}
          onChange={handleStatus}
          className="industry-select"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value='active'>Review Maiden-1</MenuItem>
          <MenuItem value='active'>Review Maiden-2</MenuItem>
          <MenuItem value='active'>Review Maiden-3</MenuItem>
          <MenuItem value='active'>Review Maiden-4</MenuItem>
        </Select>
      </FormControl>
  );
}
