import React, { useEffect, useRef, useState } from "react";
import { Grid, Container, Box, Link } from "@material-ui/core";
import { Formik, Form } from "formik";
import CustomButton from "../Utilties/CustomButton";
import TextInput from "../Utilties/textInput";
import * as yup from "yup";
import SignUpStyles from "./styles";
import Google from "../../images/google.svg";
import Fbook from "../../images/fbook.svg";
import square from "../../images/squre.png";
import Check from "../../images/check.svg";
import Phone from "../../images/phone.svg";
import Girl from "../../images/girl.svg";
import Review from "../../images/logoMaiden.png";
import axios from "axios";
import { withRouter, Redirect } from "react-router-dom";
import "../Competitors/styles.scss";

const Index = (props) => {
  const [showPasswrd, setShowPasswrd] = useState(false);
  const {
    header,
    headerBody,
    logoHead,
    logoImage,
    leftDiv,
    mainDiv,
    mainContainer,
    subContainer,
    background,
    imageDiv,
    signupSubDiv,
    formContainer,
    formClass,
    longInput,
    mobileImageMain,
    mobileImageSub,
    mobileImageDiv,
    title,
    pageBody,
    trial,
    spanTag,
    flexList,
    checkLabel,
    checks,
    containerLine,
    border,
    content,
    flex,
    submitBtn,
    inputText,
    inputPasswordAndEmail,
    label,
    socialBtn,
    image,
    girlImage,
    logo,
    links,
    divider,
    haveAccount,
    rightDiv,
    footer,
    containerFooter,
    copyright,
  } = SignUpStyles();
  const [checked, setChecked] = useState([111, 112, 113]);
  const [query, setQuery] = useState("");
  const [previousValue, SetPreviousValue] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDYW8cSfHUb94ybHJR0UqjyAOrajbYUMcA&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  let autoComplete;

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: ["address"],
        fields: ["address_components", "geometry"],
        componentRestrictions: { country: "us" },
      }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  let myfield;
  async function handlePlaceSelect(updateQuery) {
    // const addressObject = autoComplete.getPlace();
    // const formetted_address = addressObject.formatted_address;
    // updateQuery(formetted_address);
    setQuery(document.getElementsByName("businessAddress")[0].value);
    myfield(
      "businessAddress",
      document.getElementsByName("businessAddress")[0].value
    );
    // auto focus after input select location
    document.getElementsByName("suite")[0].focus();
  }

  const checkList = [
    {
      id: 111,
      label: "All features included in trial",
    },
    {
      id: 112,
      label: "10-minute setup",
    },
    {
      id: 113,
      label: "Cancel anytime",
    },
  ];
  const phoneRegExp = /^\(?\d{3}?\)??-??\(?\d{3}?\)??-??\(?\d{4}?\)??-?$/;
  const websiteRegex =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

  const signUpValidation = yup.object().shape({
    fName: yup
      .string()
      .required("First Name required")
      .min(3, "minimum 3 letters required"),
    lName: yup
      .string()
      .required("Last Name required")
      .min(3, "minimum 3 letters required"),
    email: yup.string().email("Invalid Email").required("Email required"),
    businessName: yup
      .string()
      .required("Business Name required")
      .min(3, "minimum 3 letters required"),

    businessAddress: yup
      .string()
      .required("Business Address required")
      .min(3, "minimum 5 letters required"),

    suite: yup.string(),

    businessPhoneNumber: yup
      .string()
      .required("Business Phone Number required")
      .matches(phoneRegExp, "Phone number is not valid"),

    website: yup
      .string()
      .required("Website required")
      .matches(websiteRegex, "website is not valid"),

    password: yup
      .string()
      .required("Password required")
      .min(6, "At Least 6 letters"),
  });

  const redirectToLogin = () => {
    // props.showAlert({ message: null, severity: "error" });
    props.history.push("/users/sign_in");
  };

  const redirectToHome = ({ redirect }) => {
    props.history.push(redirect);
    window.location.reload(false);
  };

  const createUser = (values) => {
    console.log("formValues: ", values);
    const payload = values;
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    };
    axios
      .post("/create.json", payload, { headers: headers })
      .then(function (response) {
        if (response.status === 200) {
          console.log("response", response);
          redirectToHome(response.data);
          props.showAlert({
            message: "your account has been created. please login",
            severity: "success",
          });
        } else {
          props.showAlert({
            message: "Something went wrong",
            severity: "error",
          });
        }
      })
      .catch(function (error) {
        props.showAlert({
          message: "Username has already been taken",
          severity: "error",
        });
      });
  };

  const normalizeInput = (value) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "xxx", "xxx-x", "xxx-xx", "xxx-xxx",
      if (cvLength < 7)
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

      // returns: "xxx-xxx-", xxx-xxx-x", "xxx-xxx-xx", "xxx-xxx-xxx", "xxx-xxx-xxxx"
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  return (
    <Grid container className={pageBody}>
      <Grid items xs={12} className={header}>
        <Grid container className={headerBody}>
          <Link href="/landing" className={logoHead}>
            <img src={Review} className={logoImage} />
          </Link>
        </Grid>
      </Grid>
      <Grid container className={mainContainer}>
        <Grid items className={subContainer}>
          <Grid container className={mainDiv}>
            <Grid items className={leftDiv}>
              <Box className={signupSubDiv}>
                <h1 className={title}>Create your account</h1>
                <div className={trial}>
                  Create your account to start your{" "}
                  <span className={spanTag}>14-day free trial</span>.
                </div>
                <Box pt={"10px"}>
                  {checkList.map((i) => {
                    return (
                      <div className={flexList}>
                        <div className={imageDiv}>
                          <img src={Check} className={checks} />
                        </div>
                        <div className={checkLabel}>{i.label}</div>
                      </div>
                    );
                  })}
                </Box>
              </Box>
              <Box className={formContainer}>
                <Formik
                  enableReinitialize
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    fName: "",
                    lName: "",
                    email: "",
                    businessName: "",
                    businessAddress: "",
                    suite: "",
                    businessPhoneNumber: "",
                    website: "",
                    password: "",
                  }}
                  validationSchema={signUpValidation}
                  onSubmit={(values, actions) => {
                    createUser(values);
                  }}
                >
                  {({ handleSubmit, setFieldValue }) => (
                    <Form
                      autoComplete="off"
                      autoFill="off"
                      className={formClass}
                    >
                      <TextInput
                        className={inputText}
                        labelClass={label}
                        name="fName"
                        type="text"
                        labelName={"First Name"}
                        placeholder={"First Name"}
                      />
                      <TextInput
                        className={inputText}
                        name="lName"
                        type="text"
                        labelName={"Last Name"}
                        placeholder={"Last Name"}
                        labelClass={label}
                      />
                      <TextInput
                        className={inputPasswordAndEmail}
                        wrapperClass={longInput}
                        name="email"
                        type="email"
                        labelName={"Email"}
                        placeholder={"Email"}
                        labelClass={label}
                      />
                      <TextInput
                        className={inputText}
                        wrapperClass={longInput}
                        name="businessName"
                        type="text"
                        labelName={"Business Name"}
                        placeholder={"Business Name"}
                        labelClass={label}
                      />
                      <TextInput
                        className={inputText}
                        wrapperClass={longInput}
                        name="businessAddress"
                        type="text"
                        labelName={"Business Address"}
                        placeholder={"Business Address"}
                        labelClass={label}
                        autoCompleteRef={autoCompleteRef}
                        onChange={(event) => {
                          console.log("focus: ", event);
                          setQuery(event.target.value);
                          myfield = setFieldValue;
                          // setFieldValue("businessAddress", event.target.value);
                        }}
                        value={query}
                      />

                      <TextInput
                        className={inputText}
                        wrapperClass={longInput}
                        name="suite"
                        type="text"
                        labelName={"Suite"}
                        placeholder={"Suite"}
                        labelClass={label}
                      />

                      <TextInput
                        className={inputText}
                        wrapperClass={longInput}
                        name="businessPhoneNumber"
                        type="text"
                        labelName={"Business Phone Number"}
                        placeholder={"555-351-1234"}
                        labelClass={label}
                        onChange={(event) => {
                          setFieldValue(
                            "businessPhoneNumber",
                            normalizeInput(event.target.value)
                          );
                          SetPreviousValue(event.target.value);
                        }}
                      />
                      <TextInput
                        className={inputPasswordAndEmail}
                        wrapperClass={longInput}
                        name="website"
                        type="text"
                        labelName={"Website"}
                        placeholder={"www.yourdomain.com"}
                        labelClass={label}
                      />
                      <TextInput
                        className={inputPasswordAndEmail}
                        wrapperClass={longInput}
                        name="password"
                        type={showPasswrd ? "text" : "password"}
                        labelName={"Password"}
                        placeholder={"Password"}
                        labelClass={label}
                        showEye={showPasswrd}
                        onEye={() => setShowPasswrd(false)}
                        onEyeOff={() => setShowPasswrd(true)}
                        colorEye="primary"
                        colorEyeOff="primary"
                      />

                      <CustomButton
                        title={"Create account"}
                        className={`${submitBtn} ${longInput}`}
                      />
                    </Form>
                  )}
                </Formik>
              </Box>
              <div className={containerLine}>
                <div className={border} />
                <span className={content}>Or</span>
                <div className={border} />
              </div>
              <CustomButton
                title={"Sign Up with Google"}
                className={socialBtn}
                startIcon={<img src={Google} />}
              />
              <CustomButton
                title={"Sign Up with Facebook"}
                className={socialBtn}
                startIcon={<img src={Fbook} />}
              />
              <CustomButton
                title={"Sign Up with Square"}
                className={socialBtn}
                startIcon={<img src={square} />}
              />
              <Box mt={2}>
                <div className={trial}>
                  By Signing up to Review Maiden, you agree to our
                </div>
                <div className={trial}>
                  <Link
                    className={links}
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    className={links}
                    style={{ textDecoration: "underline" }}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </Box>
              <div className={divider} />
              <Box mt={4}>
                <div className={haveAccount} align="center">
                  Already have an account?{" "}
                  <Link
                    onClick={() => {
                      window.location.href = "/users/sign_in";
                    }}
                    className={links}
                  >
                    Sign in
                  </Link>
                </div>
              </Box>
            </Grid>
            <Grid container className={rightDiv}>
              <img src={Phone} className={image} />
              <img src={Girl} className={girlImage} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={background}></Grid>
      </Grid>
      <Grid container className={mobileImageMain}>
        <div className={mobileImageSub}>
          <Grid container className={mobileImageDiv}>
            <img src={Phone} className={image} />
            <img src={Girl} className={girlImage} />
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} className={footer}>
        <Container className={containerFooter}>
          <img src={Review} className={logo} />
          <span align="center" className={copyright}>
            Copyright <span>&copy;</span> 2022 Review Maiden
          </span>
        </Container>
      </Grid>
    </Grid>
  );
};
export default withRouter(Index);
