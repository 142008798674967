import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import ClientStep2 from "./components/Login/ClientStep2";
import ClientStep3 from "./components/Login/ClientStep3";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import NewPassword from "./components/ForgotPassword/NewPassword";
import AlertComponent from "./components/AlertComponent/AlertComponent";
import HomeScreen from "./components/HomeScreen/Index";
// import "/app/javascript/app.css";
// import "/app/javascript/packs/app.css";

function App() {
  const [alertMessage, updateAlertMessage] = useState({
    message: null,
    severity: "error",
  });

  return (
    <Router>
      <Switch>
        <Route path="/landing" exact={true}>
          <HomeScreen showAlert={updateAlertMessage} />
        </Route>
        <Route path="/users/sign_in" exact={true}>
          <Login showAlert={updateAlertMessage} />
        </Route>
        <Route path="/users/password/new" exact={true}>
          <ForgotPassword showAlert={updateAlertMessage} />
        </Route>
        <Route path="/users/password/edit" exact={true}>
          <NewPassword showAlert={updateAlertMessage} />
        </Route>
        <Route path="/users/sign_up" exact={true}>
          { localStorage.getItem("step") === "2" ? <ClientStep2 showAlert={updateAlertMessage} /> : <ClientStep3 showAlert={updateAlertMessage} />}
        </Route>
      </Switch>
      <AlertComponent
        alertMessage={alertMessage}
        hideError={updateAlertMessage}
      />
    </Router>
  );
}

export default App;
