import { Button } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const CustomButton = (props) => {
  // const { button } = useStyles();
  return (
    <Button
      className={[props.className]}
      // onClick={props.onClick}
      component={props.component}
      to={props.to}
      variant={props.variant}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      type="submit"
    >
      {props.title}
      {props.children}
    </Button>
  );
};

export default CustomButton;
