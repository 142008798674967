import React from "react";
import { TextField } from "@material-ui/core";
import ComponentStyle from "./style";

const CustomInput = ({
  value,
  onChange,
  name,
  placeholder,
  startIcon,
  type,
  id,
}) => {
  const { input, placeHolder, cssLabel } = ComponentStyle();
  return (
    <TextField
      variant="outlined"
      label={placeholder}
      className={input}
      value={value}
      onChange={onChange}
      name={name}
      type={type}
      required
      // placeholder={placeholder}
      InputProps={{
        startAdornment: startIcon,
        disableUnderline: true,
        classes: { input: placeHolder },
      }}
      id={id}
      InputLabelProps={{
        classes: {
          root: cssLabel,
        },
      }}
    />
  );
};
export default CustomInput;
