import { makeStyles } from "@material-ui/core/styles";
const textInputStyles = makeStyles((theme) => ({
  inputStyle: {
    maxWidth: '100%',
    "@media (max-width: 550px) ": {
      flex: 1,
    },
  },
  forgetInput: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    "@media (min-width: 551px) and (max-width: 650px) ": {
      padding: "0.4rem 0.1rem",
      width: "75%",
    },
    "@media (max-width: 550px) ": {
      padding: "0.4rem 0.1rem",
      width: "100%",
    },
  },
  error: {
    color: "red",
    fontSize: 15,
    fontFamily: "Thicccboi, sans-serif",
    paddingLeft: "0.2rem",
    "@media (max-width: 550px) ": {
      fontSize: 12,
    },
  },
  passwordEye: {
    position: 'relative',
    marginRight: '15px',
    float: 'right',
    bottom: 38,
  },
  myInput: {
    "&::placeholder": {
      fontSize: 18,
      fontFamily: "Thicccboi, sans-serif",
    },
  },
}));
export default textInputStyles;
