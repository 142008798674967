import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Checkbox, CssBaseline, FormControlLabel, Grid, Link, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import loginBackground from "../../images/login_background.jpg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Steps from './Steps';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    //for mobile
    "@media (min-width: 320px) and (max-width: 768px)": {
      height: "auto",
    },
  },
  image: {
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    //for mobile and tablets
    "@media (min-width: 320px) and (max-width: 1000px)": {
      display: "none",
    },
    "@media (max-width: 320px)": {
      display: "none",
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    //for mobile and tablets
    "@media (min-width: 320px) and (max-width: 1000px)": {
      minWidth: "200px",
      maxWidth: "300px",
    },
    "@media (max-width: 320px)": {
      minWidth: "200px",
      maxWidth: "280px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: "#25bcfb",
    color: "#ffffff",
    height: '43px',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    fontSize: '20px',
    "&:hover": {
      backgroundColor: "#25bcfb!important",
      color: "#ffffff",
    },
  },

  Myroot: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {

    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4c4c4",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#25bcfb",
    },
  },

  cssLabel: {
    color: "#757575",
    "&.Mui-focused": {
      color: "#25bcfb",
    },
    marginTop: "-5px",
  }
}));


var Cookie = {
  set: function createCookie(name, value, days) {
    var expires;

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
  },
  get: function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ')
        c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  },
  delete: function eraseCookie(name) {
    Cookie.set(name, "", -1);
  }

}

const ClientStep2 = (props) => {
  const [state, setState] = useState({
    email: Cookie.get("email"),
    provider_id: Cookie.get("provider_id"),
    username: Cookie.get("username").replace(/\+/g, ' '),
    businessName: Cookie.get("businessName").replace(/\+/g, ' '),
    businessAddress: Cookie.get("businessAddress").replace(/\+/g, ' '),
    suiteNumber: Cookie.get("suiteNumber").replace(/\+/g, ' '),
    country: Cookie.get("country").replace(/\+/g, ' '),
    State: Cookie.get("State").replace(/\+/g, ' '),
    postalCode: Cookie.get("postalCode").replace(/\+/g, ' '),
    city: Cookie.get("city").replace(/\+/g, ' '),
    website: Cookie.get("website").replace(/\+/g, ' '),
    phone: Cookie.get("phone").replace(/\+/g, ' '),
    logo: " ",
    // logo: Cookie.get("logo").replace(/\+/g, ' '),
    successMessage: null,
  });

  localStorage.setItem("step", 2);

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(id);
    console.log(value);
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const classes = useStyles();

  const saveData = () => {
    Cookie.set("email", state.email, 30)
    Cookie.set("username", state.username, 30)
    Cookie.set("businessName", state.businessName, 30)
    Cookie.set("businessAddress", state.businessAddress, 30)
    Cookie.get("suiteNumber", state.suiteNumber, 30)
    Cookie.set("country", state.country, 30)
    Cookie.set("State", state.State, 30)
    Cookie.set("postalCode", state.postalCode, 30)
    Cookie.set("city", state.city, 30)
    Cookie.set("website", state.website, 30)
    Cookie.set("phone", state.phone, 30)
    Cookie.set("logo", state.logo, 30)
  }

  const handleSubmitClick = (e) => {
    e.preventDefault();
    saveData();
    const payload = {
      user: {
        email: state.email,
        provider_id: state.provider_id,
        username: state.username,
        businessName: state.businessName,
        businessAddress: state.businessAddress,
        suiteNumber: state.suiteNumber,
        country: state.country,
        State: state.State,
        city: state.city,
        postalCode: state.postalCode,
        website: state.website,
        phone: state.phone,
        logo: state.logo,
      },
    };
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    };
    axios
      .post("/users/square/sign_up", payload, { headers: headers })
      .then(function (response) {
        console.log(response);
        console.log(response.json);
        console.log(response.error);
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            successMessage: "Data saved successfully",
          }));
          props.showAlert({ message: 'Success, redirecting to next step', severity: "success" })
          nextStep();
        } else {
          props.showAlert({
            message: "Something went wrong please try later!",
            severity: "error",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 403) {
          props.showAlert({
            message: error.response.data.error,
            severity: "error",
          });
        } else {
          props.showAlert({
            message: "Something went wrong",
            severity: "error",
          });
        }
      });
  };

  const nextStep = () => {
    localStorage.setItem("step", 3);
    window.location.href = "/users/sign_up";
  };

  const logo = document.querySelector("#logo").getAttribute("src");

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <Grid
          style={{
            background: "#fff",
            maxWidth: "41%",
            borderRadius: 5,
            marginLeft: "3%",
            padding: "20px",
          }}
        >
          <Typography
            style={{ color: "#000", fontWeight: "bold", fontSize: 15 }}
          >
            Gather reviews. Gather feedback.
          </Typography>
          <Typography
            style={{ color: "#000", fontWeight: "bold", fontSize: 15 }}
          >
            Understand your customers.
          </Typography>
          <Typography
            style={{
              color: "#000",
              fontSize: 12,
              lineHeight: "normal",
              padding: "15px 0px",
            }}
          >
            Send review and feedback requests automatically while gathering key
            insights to understand your customers.
          </Typography>
          <ul style={{ fontSize: 12, color: "#000", listStyle: "inside", paddingBottom: "20px" }}>
            <li>Centralize all your reviews in one place</li>
            <li>Send review requests</li>
            <li>Send feedback requests (NPS, CSAT, etc.)</li>
            <li>Display reviews on your website</li>
            <li>Share reviews and feedback to social media</li>
            <li>Understand text sentiment</li>
          </ul>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        square
        style={{ boxShadow: "none" }}
      >
        <div className={classes.paper}>
          <Grid item sm={5} style={{ maxWidth: "72.666667%", marginBottom: "10px" }}>
            <Link href="https://www.reviewmaiden.com" target="_blank">
              <img src={logo} className={classes.logo} />
            </Link>
          </Grid>
          <Typography component="h3" variant="h5" style={{ fontWeight: "bold", fontSize: "1.3rem", marginBottom: "40px" }}>
            Complete your business profile
          </Typography>

          <Steps
            step={2}

          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            style={{ marginTop: "5px" }}
            value={state.email}
            onChange={handleChange}
            className={classes.Myroot}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            style={{ marginTop: "5px" }}
            value={state.username}
            onChange={handleChange}
            className={classes.Myroot}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="businessName"
            label="Business Name"
            name="businessName"
            autoComplete="businessName"
            style={{ marginTop: "5px" }}
            value={state.businessName}
            onChange={handleChange}
            className={classes.Myroot}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="businessAddress"
            label="Business Address"
            type="text"
            id="businessAddress"
            value={state.businessAddress}
            onChange={handleChange}
            className={classes.Myroot}
            style={{ marginTop: "5px" }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="suiteNumber"
            label="Suite Number"
            type="text"
            id="suiteNumber"
            value={state.suiteNumber}
            onChange={handleChange}
            className={classes.Myroot}
            style={{ marginTop: "5px" }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="country"
            label="Country"
            type="text"
            id="Country"
            value={state.country}
            onChange={handleChange}
            className={classes.Myroot}
            style={{ marginTop: "5px" }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="state"
            label="State"
            type="text"
            id="State"
            value={state.State}
            onChange={handleChange}
            className={classes.Myroot}
            style={{ marginTop: "5px" }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="postalCode"
            label="Postal Code"
            type="number"
            id="postalCode"
            value={state.postalCode}
            onChange={handleChange}
            className={classes.Myroot}
            style={{ marginTop: "5px" }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="city"
            label="City"
            type="text"
            id="city"
            value={state.city}
            onChange={handleChange}
            className={classes.Myroot}
            style={{ marginTop: "5px" }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="website"
            label="Website"
            type="text"
            id="website"
            value={state.website}
            onChange={handleChange}
            className={classes.Myroot}
            style={{ marginTop: "5px" }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Phone"
            type="text"
            id="phone"
            value={state.phone}
            style={{ marginTop: "5px" }}
            onChange={handleChange}
            className={classes.Myroot}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
              style: { color: "#b1b6b8" }
            }}
            onInput={(e) => {
              const parsed = parseInt(e.target.value);
              if (isNaN(parsed)) {
                e.target.value = "";
              } else {
                e.target.value = Math.max(0, parsed).toString().slice(0, 13);
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                +1
              </InputAdornment>,
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmitClick}
          >
            Save & Continue
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
export default ClientStep2;
