import { makeStyles } from "@material-ui/core";
const PasswordStyle = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ededed",
  },
  container: {
    backgroundColor: "white",
    borderRadius: "5px",
    border: "2px solid #d8d8d8",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: { width: "50px", height: "40px", backgroundColor: "blue" },
  linkStyle: { textDecoration: "none", color: "#12bbd6", marginTop: "1.2rem" },
}));

export default PasswordStyle;
