import React, { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function AlertComponent(props) {
  const [open, setOpen] = React.useState(false);

  const openAlert = () => {
    toggleDisplay("block");
  };
  const closeAlert = () => {
    setOpen(false);
    props.hideError(null);
  };

  useEffect(() => {
    if (props.alertMessage !== null && props.alertMessage.message !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  });

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={60000}
      // onClose={closeAlert} //use only to hide the alert when user click anywhere on the screen
    >
      <Alert onClose={closeAlert} severity={props.alertMessage?.severity}>
        {props.alertMessage?.message}
      </Alert>
    </Snackbar>
  );
}

export default AlertComponent;
