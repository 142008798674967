import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Checkbox, CssBaseline, FormControlLabel, Grid, Link, Paper, TextField } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { withRouter, Redirect } from "react-router-dom";
import Copyright from "../Copyright";
import loginBackground from "../../images/login_background.jpg";
import { isMobile } from "react-device-detect";
import MuiSelect from '../customComponents/MuiSelect'
import Logo from '../customComponents/Logo'

import Steps from './Steps';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    //for mobile
    "@media (min-width: 320px) and (max-width: 768px)": {
      height: "auto",
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    //for mobile and tablets
    "@media (min-width: 320px) and (max-width: 1000px)": {
      minWidth: "200px",
      maxWidth: "300px",
    },
    "@media (max-width: 320px)": {
      minWidth: "200px",
      maxWidth: "280px",
    },
  },
  submit: {
    width: "40%",
    margin: theme.spacing(1, 0, 2),
    height: '43px',
    fontWeight: 'normal',
    fontSize: '20px',
    "&:hover": {
      backgroundColor: "#25bcfb!important",
      color: "#ffffff",
    },
  },
  continueSubmit: {
    backgroundColor: "#25bcfb",
    color: "#ffffff",
  },
  backSubmit: {
    marginRight: "20%",
    backgroundColor: "white",
    color: "gray"
  }
}));


var Cookie = {
    set: function createCookie(name, value, days) {
        var expires;

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    },
    get: function readCookie(name) {
        var nameEQ = encodeURIComponent(name) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    },
    delete: function eraseCookie(name) {
        Cookie.set(name, "", -1);
    }

}

const ClientStep3 = (props) => {
  const [state, setState] = useState({
    username: Cookie.get("username")
  });

  localStorage.setItem("step", 3);
  useEffect(() => {
    console.log("this.props.location.state", history);
    showAlertMessage();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const classes = useStyles();

  const clearData = () => {
    Cookie.delete("email")
    Cookie.delete("username")
    Cookie.delete("businessName")
    Cookie.delete("businessAddress")
    Cookie.delete("country")
    Cookie.delete("State")
    Cookie.delete("postalCode")
    Cookie.delete("city")
    Cookie.delete("website")
    Cookie.delete("phone")
    Cookie.delete("industry")
  }

  const handleSubmitClick = (e) => {
    e.preventDefault();
    const payload = {
      user: {
        username: state.username,
        provider: "square"
      },
    };
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    };
    axios
      .post("/users/sign_in.json", payload, { headers: headers })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            successMessage: "Login successful. Redirecting to home page..",
          }));
          redirectToHome();
          // props.showAlert({message: '', severity: "success"})
        } else if (response.code === 204) {
          props.showAlert({
            message: "Username and password do not match",
            severity: "error",
          });
        } else {
          props.showAlert({
            message: "Username does not exists",
            severity: "error",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        props.showAlert({
          message: "Username and password do not match",
          severity: "error",
        });
      });
  };

  const handleBackClick = () => {
    localStorage.setItem("step", 2)
    window.location.href = "/users/sign_up";
  }

  const redirectToHome = () => {
    clearData();
    localStorage.setItem("step", 2)
    window.location.href = "/dashboard/account-setup"
  };
  const logo = document.querySelector("#logo").getAttribute("src");

  const showAlertMessage = () => {
    if (history.state?.state?.reset) {
      props.showAlert({
        message:
          "You have successfully changed your password. You can now login into your account below",
        severity: "success",
      });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <div className={classes.paper} style={{ width: "40%" }}>
        <Grid item sm={5} style={{ maxWidth: "72.666667%", marginBottom: "10px" }}>
          <Link href="https://www.reviewmaiden.com" target="_blank">
            <img src={logo} className={classes.logo} />
          </Link>
        </Grid>
        <Typography component="h3" variant="h5" style={{ fontWeight: "bold", fontSize: "1.3rem", marginBottom: "40px" }}>
          Insert snippet onto Square website
        </Typography>

        <Steps
          step={3}
        />
        <Box>
          Please follow the following steps:
          <p>
            1. Goto the connections tab and ensure all your sites are fetched from Square
          </p>
          <p>
            2. Connect your site with a location
          </p>
          <p>
            3. Create an embeddable widget with the type `Popup Widget`
          </p>
          <p>
            4. Upload the snippet to the square site
          </p>
        </Box>
        <Grid style={{ width: "100%" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={`${classes.submit} ${classes.backSubmit}`}
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={`${classes.submit} ${classes.continueSubmit}`}
            onClick={handleSubmitClick}
          >
            Continue
          </Button>
        </Grid>
      </div>
    </Grid>
  );
}
export default ClientStep3;
