import React from 'react'

const steps = ({step}) => {
	return (
        <div className="multisteps-form__progress" style={{ width: "110%", marginBottom: "15px"}}>
          <span className={`multisteps-form__progress-btn step-1 ${step >= 1 ? " js-active" : ""}`}>
          	<span>Step 1</span>
          </span>
          <span className={`multisteps-form__progress-btn step-2 ${step >= 2 ? " js-active" : ""}`}>
          	<span>Step 2</span>
          </span>
          <span className={`multisteps-form__progress-btn step-3 ${step >= 3 ? " js-active" : ""}`}>
          	<span>Step 3</span>
          </span>
          <span className={`multisteps-form__progress-btn step-4 ${step >= 4 ? " js-active" : ""}`}>
          	<span>Step 4</span>
          </span>
        </div>
	)
}

export default steps;
