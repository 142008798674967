import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { withRouter, Redirect } from "react-router-dom";

import { Container, Grid, Box, Typography, Link } from "@material-ui/core";
import CustomInput from "../customComponents/TextField";
import CustomButton from "../customComponents/CustomButton";
import PasswordStyle from "./style.js";

function ForgotPassword(props) {
  const [state, setState] = useState({
    username: "",
    successMessage: null,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    const payload = {
      username: state.username,
    };
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    };
    axios
      .post("/users/password.json", payload, { headers: headers })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            successMessage: "Login successful. Redirecting to home page..",
          }));
          redirectToLogin();
          props.showAlert({
            message:
              "You will receive an email with instructions on how to reset your password in a few minutes.",
            severity: "success",
          });
        } else {
          props.showAlert({ message: "Username not found", severity: "error" });
        }
      })
      .catch(function (error) {
        console.log(error);
        props.showAlert({ message: "Username not found", severity: "error" });
      });
  };

  const redirectToLogin = () => {
    props.showAlert({ message: null, severity: "error" });
    props.history.push("/users/sign_in");
  };
  const logo = document.querySelector("#logo").getAttribute("src");
  const { root, container, flexCenter, linkStyle } = PasswordStyle();

  return (
    <Grid className={root}>
      <Container className={container} maxWidth="sm">
        <Box component="div" className={flexCenter}>
          <Grid item sm={5}>
            <Link href="https://www.reviewmaiden.com" target="_blank">
              <img src={logo} />
            </Link>
          </Grid>
        </Box>
        <Box component="div" mt="1rem" mb="1rem">
          <Typography style={{ fontSize: "28px", fontWeight: "500" }}>
            Reset Password
          </Typography>
        </Box>
        <Box component="div" textAlign="center" mb="1rem">
          <Typography style={{ fontSize: "18px", fontWeight: "400" }}>
            Enter the username you used to register with Review Maiden and we'll
            send you instruction to reset your password
          </Typography>
        </Box>
        <CustomInput
          placeholder="Username"
          type="text"
          name="username"
          id="username"
          autoComplete="username"
          autoFocus
          value={state.username}
          onChange={handleChange}
        />
        <CustomButton title="Send Instructions" onClick={handleSubmitClick} />

        <Link href="/" className={linkStyle} target="blank">
          I don't remember my username
        </Link>
        <Link href="/users/sign_in" target="blank" className={linkStyle}>
          Back to login
        </Link>
      </Container>
    </Grid>
  );
}
export default withRouter(ForgotPassword);
