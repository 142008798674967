import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import axios from "axios";
import { withRouter, Redirect } from "react-router-dom";

// import React, { useState } from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import CustomInput from "../customComponents/TextField.js";
import CustomButton from "../customComponents/CustomButton";
import PasswordStyle from "./style.js";

function NewPassword(props) {
  const [isNewUser, setIsNewUser] = useState(false);
  const [state, setState] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const { root, container, flexCenter } = PasswordStyle();

  useEffect(() => {
    if (window.location.search.split("new_user=true").length > 1) {
      setIsNewUser(true);
    }
  }, []);

  const handleSubmitClick = (e) => {
    e.preventDefault();
    var query = new URLSearchParams(props.location.search);
    var data = {};
    for (let params of query.entries()) {
      data[params[0]] = params[1];
    }
    if (state.password != state.password_confirmation) {
      props.showAlert({
        message: "Password confirmation doesn't match Password",
        severity: "error",
      });
      return;
    }
    const payload = {
      user: {
        reset_password_token: data["reset_password_token"],
        password: state.password,
        password_confirmation: state.password_confirmation,
        set_password: isNewUser,
      },
    };
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const headers = {
      "X-CSRF-Token": csrf,
    };
    axios
      .put("/users/password", payload, { headers: headers })
      .then(function (response) {
        console.log("response is: ", response);
        if (response.status === 200 || response.status === 302) {
          redirectToLogin(true);
        } else {
          props.showAlert({
            message: "Password confirmation doesn't match Password",
            severity: "error",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        props.showAlert({
          message: "Username does not exists",
          severity: "error",
        });
      });
  };

  const redirectToLogin = (reset = false) => {
    // props.showAlert({message: null, severity: "error"})

    props.history.push({
      pathname: isNewUser ? "/dashboard/account-setup" : "/users/sign_in",
      state: { reset: true },
    });
    window.location.reload(false);
  };
  const redirectToDashboard = () => {
    props.showAlert({ message: null, severity: "error" });
    props.history.push("/");
  };
  const logo = document.querySelector("#logo").getAttribute("src");

  return (
    <Grid className={root}>
      <Container className={container} maxWidth="sm">
        <Box component="div" className={flexCenter}>
          <Grid item sm={5}>
            <Link href="https://www.reviewmaiden.com" target="_blank">
              <img src={logo} />
            </Link>
          </Grid>
        </Box>
        <Box component="div" mt="1rem" mb="1rem">
          <Typography style={{ fontSize: "28px", fontWeight: "500" }}>
            Create Password
          </Typography>
        </Box>
        <Box component="div" textAlign="center" pl="3rem" pr="3rem" mb="1rem">
          <Typography style={{ fontSize: "18px", fontWeight: "400" }}>
            Type and confirm a secure password for your Review Maiden account
          </Typography>
        </Box>
        <CustomInput
          placeholder="Enter Password"
          onChange={(v) => setinput(v)}
          name="ËnterPass"
          id="password"
          type="password"
          value={state.password}
          onChange={handleChange}
        />
        <CustomInput
          placeholder="Confirm Password"
          onChange={(v) => setinput(v)}
          value={state.password_confirmation}
          name="ConfirmPass"
          type="password"
          id="password_confirmation"
          onChange={handleChange}
        />
        <CustomButton
          title={isNewUser ? "Create Password" : "Change My Password"}
          onClick={handleSubmitClick}
        />
      </Container>
    </Grid>
  );
}
export default withRouter(NewPassword);
