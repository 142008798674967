import { makeStyles } from "@material-ui/core/styles";
const SignUpStyles = makeStyles((theme) => ({
  pageBody: {
    fontFamily: "Thicccboi, sans-serif",
    backgroundColor: "#fff",
    color: "#7a7d9c",
    fontSize: 18,
    lineHeight: "1.667em",
    fontWeight: 500,
  },
  header: {
    padding: "27px 0",
    borderBottom: "1px solid #d9dbe9",
    backgroundColor: "#ffffff",
    position: "relative",
    zIndex: 1000,
  },
  headerBody: {
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1246,
    margin: "0 auto",
    padding: "0 24px",
  },
  logoHead: {
    paddingLeft: 0,
    transitionProperty: "transform, -webkit-transform",
    position: "relative",
    float: "left",
    textDecoration: "none",
    color: "#333333",
    transition: "color 300ms ease",
    backgroundColor: "transparent",
    "&:hover": {
      transform: "scale(0.95)",
    },
  },
  logoImage: {
    display: "inline-block",
    maxWidth: "100%",
    verticalAlign: "middle",
    border: 0,
    width: 240,
    "@media (max-width: 767px)": {
      width: "170px",
    },
  },
  mainDiv: {
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 991px) ": {
      flexDirection: "column",
    },
  },
  leftDiv: {
    width: "100%",
    maxWidth: "40%",
    "@media (max-width: 991px) ": {
      maxWidth: 484,
    },
  },
  rightDiv: {
    position: "relative",
    display: "flex",
    maxWidth: "43%",
    flexDirection: "column",
    "@media (max-width: 991px) ": {
      display: "none",
    },
  },
  mainContainer: {
    paddingTop: "116px",
    paddingBottom: "116px",
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 991px)": {
      paddingTop: 60,
      paddingBottom: 60,
    },
    "@media (max-width: 767px)": {
      paddingTop: 50,
    },
    "@media (max-width: 479px)": {
      paddingTop: 40,
    },
  },
  subContainer: {
    margin: "0 auto",
    padding: "0 27px",
    maxWidth: "1246px",
    justifyContent: "space-between",
    zIndex: 1,
    "@media (max-width: 479px)": {
      display: "block",
      padding: "0 16px",
      flexDirection: "column",
      flexWrap: "nowrap",
      gridAutoFlow: "row",
      gridAutoColumns: "1fr",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto auto",
    },
    "@media (max-width: 991px)": {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  background: {
    left: "auto",
    top: "0%",
    right: "0%",
    bottom: "0%",
    width: "50%",
    backgroundColor: "#f3f5ff",
    position: "absolute",
    "@media (max-width: 479px)": {
      minHeight: "35%",
    },
    "@media (max-width: 767px)": {
      minHeight: "38%",
    },
    "@media (max-width: 991px)": {
      left: "0%",
      top: "auto",
      right: "0%",
      bottom: "0%",
      display: "none",
      width: "100%",
      minHeight: "50%",
    },
  },
  signupSubDiv: {
    marginBottom: "40px",
    "@media (max-width: 479px)": {
      marginBottom: "30px",
    },
    "@media (max-width: 767px) ": {
      marginBottom: "35px",
    },
  },
  formContainer: {
    minHeight: "391px",
    margin: "0 0 15px",
    "@media (max-width: 479px)": {
      minHeight: 522,
    },
    "@media (max-width: 767px) ": {
      minHeight: 530,
    },
  },
  formClass: {
    display: "grid",
    gridAutoColumns: "1fr",
    gridColumnGap: "26px",
    gridRowGap: "32px",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "auto",
    "@media (max-width: 767px)": {
      gridTemplateColumns: "1fr",
    },
  },
  longInput: {
    gridColumnStart: "span 2",
    gridColumnEnd: "span 2",
    gridRowStart: "span 1",
    gridRowEnd: "span 1",
    "@media (max-width: 767px)": {
      gridColumnStart: "span 1",
      gridColumnEnd: "span 1",
    },
  },
  title: {
    fontFamily: "Thicccboi-bold, sans-serif",
    color: "#0b0a33",
    fontSize: 40,
    lineHeight: "1.4em",
    fontWeight: 700,
    marginBottom: 16,
    letterSpacing: "-0.01em",
    textTransform: "none",
    "@media (max-width:991px)": {
      marginBottom: 10,
      fontSize: 33,
    },
    "@media (max-width: 767px)": {
      marginBottom: 5,
      fontSize: 28,
    },
    "@media (max-width: 479px)": {
      fontSize: 27,
    },
  },
  trial: {
    fontFamily: "Thicccboi, sans-serif",
    "@media (max-width: 479px)": {
      fontSize: 16,
    },
    "@media (max-width: 767px)": {
      lineHeight: "1.4em",
    },
  },
  spanTag: {
    backgroundColor: "rgba(68, 87, 255, 0.2)",
  },
  flexList: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  mobileImageMain: {
    display: "none",
    backgroundColor: "#f3f5ff",
    padding: "176px 0",
    "@media (max-width: 991px)": {
      display: "block",
      padding: "60px 0 86px 0",
    },
    "@media (max-width: 767px)": {
      paddingBottom: 68,
    },
  },
  mobileImageSub: {
    display: "block",
    maxWidth: "1246px",
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: "24px",
    paddingLeft: "24px",
    justifyContent: "space-between",
    "@media (max-width: 991px)": {
      flexDirection: "column",
      alignItems: "stretch",
    },
    "@media (max-width: 479px)": {
      display: "block",
      paddingight: 16,
      paddingLeft: 16,
      flexDirection: "column",
      flexWrap: "nowrap",
      gridAutoFlow: "row",
      gridAutoColumns: "1fr",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto auto",
    },
  },
  mobileImageDiv: {
    maxWidth: 510,
    position: "relative",
    flexDirection: "column",
    margin: "0 auto",
  },
  imageDiv: {
    paddingRight: "20px",
  },
  checks: {
    borderRadius: "10px",
    display: "inline-block",
    verticalAlign: "middle",
  },
  checkLabel: {
    fontFamily: "Thicccboi, sans-serif",
    color: "#7a7d9c",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "1.667em",
    textTransform: "none",
  },
  containerLine: {
    display: "flex",
    alignItems: "center",
    margin: "60px 0px",
  },
  border: {
    borderBottom: "1px solid #d9dbe9",
    width: "100%",
  },
  content: {
    paddingRight: theme.spacing(0.2),
    paddingLeft: theme.spacing(0.2),
    fontWeight: 500,
    fontSize: 18,
    color: "#7a7d9c",
    fontFamily: "Thicccboi, sans-serif",
    textTransform: "capitalize",
  },
  flex: {
    display: "flex",
    "@media (max-width: 767px)": {
      display: "block",
    },
  },
  inputText: {
    display: "block",
    width: "100%",
    height: "38px",
    padding: "8px 12px",
    verticalAlign: "middle",
    border: "1px solid #cccccc",
    fontFamily: "Thicccboi, sans-serif",
    textTransform: "capitalize",
    backgroundColor: "white",
    boxShadow: "0 2px 8px 0 rgba(27, 25, 121, 0.05)",
    padding: "0 32px",
    width: " 100%",
    height: " 38px",
    padding: "8px 32px",
    minHeight: "73px",
    lineHeight: "1.111em",
    borderRadius: "10000000px",
    border: "1px solid #eff0f6",
    marginBottom: 0,
    justifyContent: "center",
    fontSize: 18,
    color: "#0b0a33",
    transition: "box-shadow 300ms ease, color 300ms ease, border 300ms ease",
    "&:hover, &:focus-visible, &:focus, &:active, &:focus-within": {
      border: "1px solid #4457ff",
    },
    "@media (max-width: 479px)": {
      padding: "0 25px",
    },
    "@media (max-width: 767px)": {
      padding: "0 27px",
    },
  },
  inputPasswordAndEmail: {
    display: "block",
    width: "100%",
    height: "38px",
    padding: "8px 12px",
    verticalAlign: "middle",
    border: "1px solid #cccccc",
    fontFamily: "Thicccboi, sans-serif",
    backgroundColor: "white",
    boxShadow: "0 2px 8px 0 rgba(27, 25, 121, 0.05)",
    padding: "0 32px",
    width: " 100%",
    height: " 38px",
    padding: "8px 32px",
    minHeight: "73px",
    lineHeight: "1.111em",
    borderRadius: "10000000px",
    border: "1px solid #eff0f6",
    marginBottom: 0,
    justifyContent: "center",
    fontSize: 18,
    color: "#0b0a33",
    transition: "box-shadow 300ms ease, color 300ms ease, border 300ms ease",
    "&:hover, &:focus-visible, &:focus, &:active, &:focus-within": {
      border: "1px solid #4457ff",
    },
    "@media (max-width: 479px)": {
      padding: "0 25px",
    },
    "@media (max-width: 767px)": {
      padding: "0 27px",
    },
  },

  label: {
    fontFamily: "Thicccboi-Bold, sans-serif",
    display: "block",
    marginBottom: "15px",
    color: "#0b0a33",
    lineHeight: "1.111em",
    fontWeight: 700,
  },
  submitBtn: {
    backgroundColor: "#4457ff",
    color: "white",
    marginTop: 8,
    border: 0,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    padding: "22px 42px",
    fontSize: 18,
    textTransform: "capitalize",
    width: "100%",
    display: "inline-block",
    borderRadius: "1000000000px",
    transform: "scale3d(1, 1, 1.01)",
    transition: "all 0.3s ease",
    lineHeight: "1.111em",
    fontWeight: 700,
    textAlign: "center",
    textDecoration: "none",
    transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: "#171b3e",
      color: "#fff",
      transform: "scale3d(0.95, 0.95, 1.01)",
    },
  },
  socialBtn: {
    backgroundColor: "#ffff",
    fontFamily: "Thicccboi, sans-serif",
    color: "#7a7d9c",
    fontSize: 18,
    textAlign: "start",
    fontWeight: 500,
    lineHeight: "1.111em",
    marginBottom: "18px",
    boxShadow:
      "0 5px 5px 0 rgb(11 10 51 / 1%), 0 4px 11px 0 rgb(68 87 255 / 6%)",
    padding: "18px 20px",
    width: "100%",
    borderRadius: "1000000000px",
    border: "1px solid #eff0f6",
    transition: "all 0.5s ease-in",
    textTransform: "none",
    "&:hover": {
      transform: "scale3d(0.98, 0.98, 1.01)",
      border: "1px solid #333",
      color: "#333",
      backgroundColor: "#ffff",
    },
  },
  links: {
    transition: "color 300ms ease",
    color: "#4457ff",
    "&:hover": {
      color: "#333",
      textDecoration: "none",
    },
  },
  divider: {
    borderBottom: "1px solid #d9dbe9",
    margin: "40px 0px",
  },
  haveAccount: {
    fontFamily: "Thicccboi, sans-serif",
    color: "#7a7d9c",
    fontSize: "18px",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "1.6em",
    textTransform: "none",
    "@media (max-width: 767px)": {
      marginTop: 35,
      lineHeight: "1.4em",
    },
  },
  image: {
    marginBottom: "56px",
    display: "inline-block",
    maxWidth: "100%",
    verticalAlign: "middle",
    border: "0",
  },
  girlImage: {
    bottom: 0,
    position: "absolute",
    marginLeft: "26px",
    maxWidth: "48%",
    display: "inline-block",
    verticalAlign: "middle",
    border: "0",
    "@media (max-width: 991px)": {},
  },
  footer: {
    paddingTop: "40px",
    paddingBottom: "40px",
    alignItems: "flex-start",
    borderTop: "1px solid #d9dbe9",
    width: "100%",
    backgroundColor: "white",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 767px)": {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  containerFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  logo: {
    width: "212px",
    display: "inline-block",
    maxWidth: "100%",
    color: "#7a7d9c",
    marginLeft: "1rem",
    "@media (max-width: 767px)": {
      width: "170px",
      marginLeft: "0rem",
    },
    "@media (max-width:479px)": {
      width: "160px",
      marginLeft: "0rem",
    },
  },
  copyright: {
    fontFamily: "Thicccboi, sans-serif",
    color: "#7a7d9c",
    fontSize: "18px",
    textAlign: "end",
    fontWeight: 500,
    marginRight: ".4rem",
    "@media (max-width: 767px)": {
      fontSize: "16px",
      marginTop: ".9rem",
      marginRight: "0rem",
    },
  },
}));
export default SignUpStyles;
