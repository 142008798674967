import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Checkbox, CssBaseline, FormControlLabel, Grid, Link, Paper, TextField } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";


const Logo = (props) => {

  return (
    <Box mt={1.2} sx={{ mx: 2.5 }} alignItems="center" className="step-1-logo--container">
      <Box
        variant="contained"
        component="label"
        className='LogoImage'
      >
        Upload logo
        <input
          name="logo"
          type="file"
          hidden
          onChange={props.handleChange}
        />
      </Box>
    </Box>
  );
}
export default Logo;
