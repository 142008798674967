import { Button } from "@material-ui/core";
import React from "react";
import ComponentStyle from "./style";

const CustomButton = ({ title, onClick, component, size }) => {
  const { buttonStyle } = ComponentStyle();
  return (
    <Button
      className={buttonStyle}
      component={component}
      onClick={onClick}
      size={size}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
