import React from "react";
import { useField } from "formik";
import textInputStyles from "./styles";
import { IconButton, TextField, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const TextInput = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  const { inputStyle, forgetInput, error, passwordEye } = textInputStyles();

  let classNames = props.forgetPs ? forgetInput : inputStyle;
  if (props.wrapperClass) classNames += ` ${props.wrapperClass}`;

  return (
    <>
      <div className={classNames} style={props.style}>
        {props.labelName && (
          <label className={props.labelClass}>{props.labelName}</label>
        )}
        {props.name === "businessAddress" ? (
          <input
            autoFocus={props.focus}
            className={props.className}
            ref={props.autoCompleteRef}
            onChange={props.onChange}
            value={props.value}
            // {...field}
            {...props}
          />
        ) : (
          <input
            autocomplete="off"
            autoFocus={props.focus}
            className={props.className}
            {...field}
            {...props}
          />
        )}

        {(props.name === "password" ||
          props.name === "cPassword" ||
          props.name === "newPassword") && (
          <InputAdornment position="end" className={passwordEye}>
            {props.showEye ? (
              <IconButton onClick={props.onEye}>
                <Visibility color={props.colorEye} />
              </IconButton>
            ) : (
              <IconButton onClick={props.onEyeOff}>
                <VisibilityOff color={props.colorEyeOff} />
              </IconButton>
            )}
          </InputAdornment>
        )}
        {meta.touched && meta.error ? (
          <div className={error}>{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};
export default TextInput;
