import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {" © "}
      {new Date().getFullYear()}{" "}
      <Link
        color="inherit"
        href="https://www.reviewmaiden.com/"
        target="_blank"
      >
        Review Maiden
      </Link>{" "}
      {" | "}
      <Link
        color="inherit"
        href="https://www.reviewmaiden.com/terms-and-conditions"
        target="_blank"
      >
        Terms & Conditions
      </Link>
      {" | "}
      <Link
        color="inherit"
        href="https://www.reviewmaiden.com/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </Link>
    </Typography>
  );
}
