// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from "react";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import App from "../app";

document.addEventListener("DOMContentLoaded", () => {
  // render(<App />, document.body.appendChild(document.createElement("div")));
  var first = document.createElement("div");
  first.className = "contentDiv";
  first.style.overflowY = "auto";

  render(
    <App />,
    document.getElementsByClassName("content-height")[0]
      ? document.getElementsByClassName("content-height")[0].appendChild(first)
      : document.body.appendChild(document.createElement("div"))
  );
});
